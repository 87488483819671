<template>
  <v-container>
    <!-- BOC:[error] -->
    <ApiErrorDialog v-if="api.isError" :api="api" />
    <!-- EOC -->
    <div class="tab-bar-card pt-3 text-h4 text-uppercase brown--text">
      <div>{{ auth.Institution.name }}</div>
      <div class="text-h6">{{ auth.Institution.town }}</div>
      <div class="d-flex justify-space-between align-end">
        <!-- <v-select
          :items="lables"
          class="custom-select"
          item-class="custom-select-item"
          item-color="brown"
          background-color="brown"
          :menu-props="{
            class: 'custom-select-menu',
            'background-color': 'brown',
          }"
          style="height: 40px"
          solo-inverted
          outlined
          dense
        /> -->
        <v-select
          style="max-width: 150px"
          :items="lables"
          item-text="name"
          item-value="id"
          background-color="transparent"
          item-color="brown"
          v-model="selected"
          dense
          hide-details
          solo
          class="ma-3 custom-select"
          color="brown"
          @change="filterStudents()"
        ></v-select>
        <v-btn
          height="30"
          text
          color="grey darken-2"
          large
          class="text-capitalize"
          @click="
            () => {
              $router.push({
                name: 'PageEventSchoolSelect',
              });
            }
          "
        >
          {{ $t("string.change") }}
        </v-btn>
      </div>
    </div>
    <div class="list-panel" v-if="selected_data.length > 0">
      <div v-if="showAll">
        <div
          v-for="(student, i) in selected_data"
          :key="i"
          :class="
            auth.Player.mochiId == student.mochiId
              ? 'selected-list-card'
              : 'list-card'
          "
        >
          <div class="d-flex align-center px-2">
            {{ i + 1 }}
            <AbstractAvatar
              v-if="student.avatar"
              :avatar="student.avatar"
              :height="50"
              :width="50"
              class="ma-1"
            />
            <v-img
              v-else
              class="ma-1"
              max-height="50"
              max-width="50"
              :src="require('@/assets/entity/UnnamedCharacter1.png')"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>

            <div
              :class="
                auth.Player.mochiId == student.mochiId ? 'selected-name' : ''
              "
            >
              {{ student.name }}
            </div>
          </div>
          <div class="d-flex align-center me-2">
            {{ student.totalStar }}
            <v-icon color="yellow" class="ms-1 me-2 text-brd-sm">
              mdi-star</v-icon
            >
            <div
              class="px-2"
              :class="
                auth.Player.mochiId == student.mochiId
                  ? 'selected-score'
                  : 'score'
              "
            >
              {{ student.totalScore }}
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div v-if="topStudents.length > 0">
          <div
            v-for="(student, i) in topStudents"
            :key="i"
            :class="
              auth.Player.mochiId == student.mochiId
                ? 'selected-list-card'
                : 'list-card'
            "
          >
            <div class="d-flex align-center px-2">
              {{ i + 1 }}
              <AbstractAvatar
                v-if="student.avatar"
                :avatar="student.avatar"
                :height="50"
                :width="50"
                class="ma-1"
              />
              <v-img
                v-else
                class="ma-1"
                max-height="50"
                max-width="50"
                :src="require('@/assets/entity/UnnamedCharacter1.png')"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <div
                :class="
                  auth.Player.mochiId == student.mochiId ? 'selected-name' : ''
                "
              >
                {{ student.name }}
              </div>
            </div>
            <div class="d-flex align-center me-2">
              {{ student.totalStar }}
              <v-icon color="yellow" class="ms-1 me-2 text-brd-sm">
                mdi-star</v-icon
              >
              <div
                class="px-2"
                :class="
                  auth.Player.mochiId == student.mochiId
                    ? 'selected-score'
                    : 'score'
                "
              >
                {{ student.totalScore }}
              </div>
            </div>
          </div>
          <div
            class="d-flex justify-center brown--text text-h3 my-0"
            style="line-height: 15px"
          >
            • • •
          </div>
        </div>
        <div v-if="myMates.length > 0">
          <div
            v-for="(student, i) in myMates"
            :key="i"
            :class="
              auth.Player.mochiId == student.mochiId
                ? 'selected-list-card'
                : 'list-card'
            "
          >
            <div class="d-flex align-center px-2">
              {{ myMatecountStart + i }}
              <AbstractAvatar
                v-if="student.avatar"
                :avatar="student.avatar"
                :height="50"
                :width="50"
                class="ma-1"
              />
              <v-img
                v-else
                max-height="50"
                max-width="50"
                class="ma-1"
                :src="require('@/assets/entity/UnnamedCharacter1.png')"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <div
                :class="
                  auth.Player.mochiId == student.mochiId ? 'selected-name' : ''
                "
              >
                {{ student.name }}
              </div>
            </div>
            <div class="d-flex align-center me-2">
              {{ student.totalStar }}
              <v-icon color="yellow" class="ms-1 me-2 text-brd-sm">
                mdi-star</v-icon
              >
              <div
                class="px-2"
                :class="
                  auth.Player.mochiId == student.mochiId
                    ? 'selected-score'
                    : 'score'
                "
              >
                {{ student.totalScore }}
              </div>
            </div>
          </div>
          <div
            class="d-flex justify-center brown--text text-h3 my-0"
            style="line-height: 15px"
          >
            • • •
          </div>
        </div>
      </div>
    </div>
    <div class="list-panel" v-else>
      <div class="list-card">
        <div class="d-flex align-center px-2">No Students in this class</div>
      </div>
    </div>
    <div class="text-center mt-5" v-if="showAllBtn">
      <v-btn small color="yellow" @click="showAll = !showAll">
        {{ showAll ? $t("string.show_less") : $t("string.show_all") }}
      </v-btn>
    </div>
  </v-container>
</template>
    
    <script>
import { mapState } from "vuex";
import AvatarSkeleton from "@/components/skeleton/Avatar";
export default {
  components: {
    ApiErrorDialog: () =>
      import(
        /* webpackChunkName: "component-api-error-dialog" */ "@/components/ApiErrorDialog.vue"
      ),
    AbstractAvatar: () => ({
      component: import(
        /* webpackChunkName: "component-abstract-avatar-testing" */ "@/components/avatar/AbstractAvatar"
      ),
      loading: AvatarSkeleton,
      delay: 200,
    }),
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  data: () => ({
    myMatecountStart: 0,
    selected: -1,
    showAll: false,
    showAllBtn: false,
    topStudents: [],
    myMates: [],
    students: [],
    selected_data: [],
    classroomApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    lables: [],
  }),
  created() {
    this.lables = [{ id: -1, name: this.$t("string.all") }];
    this.api.url =
      this.$api.servers.event +
      "/api/v1/" +
      this.$_getLocale() +
      "/game/event/ranking/institution";

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };

    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.selected_data = resp;
      this.students = resp;
      this.showStudents();
    };

    this.classroomApi.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/data/institution/" +
      this.auth.Institution.id +
      "/classroom/";

    this.classroomApi.callbackReset = () => {
      this.classroomApi.isLoading = true;
      this.classroomApi.isError = false;
    };

    this.classroomApi.callbackError = (e) => {
      this.classroomApi.isLoading = false;
      this.classroomApi.isError = true;
      this.classroomApi.error = e;
    };
    this.classroomApi.callbackSuccess = (resp) => {
      this.classroomApi.isLoading = false;
      resp.forEach((element) => {
        this.lables.push(element);
      });
    };
  },
  mounted() {
    this.api.params = {
      eventCode: this.$route.params.code,
      institutionId: this.auth.Institution.id,
    };
    this.$api.fetch(this.api);

    this.classroomApi.method = "GET";
    this.$api.fetch(this.classroomApi);
  },
  methods: {
    showStudents() {
      this.myMates = [];
      this.topStudents = [];
      if (this.selected_data.length <= 5) {
        this.showAll = true;
        this.showAllBtn = false;
      } else {
        this.showAll = false;
        this.showAllBtn = true;

        let me = this.selected_data.findIndex(
          (element) => this.auth.Player.mochiId == element.mochiId
        );

        if (me > 5) {
          this.topStudents = this.selected_data.slice(0, 3);
          if (me == this.selected_data.length - 1) {
            this.myMates = this.selected_data.slice(-3);
            this.myMatecountStart = me - 2;
          } else {
            this.myMates = this.selected_data.slice(me - 1, me + 2);
            this.myMatecountStart = me;
          }
        } else {
          this.topStudents = this.selected_data.slice(0, 5);
        }
      }
    },
    filterStudents() {
      if (this.selected == -1) {
        this.selected_data = this.students;
      } else {
        this.selected_data = this.students.filter(
          (student) => student.classroomId == this.selected
        );
      }
      this.showStudents();
    },
  },
};
</script>

    <style scoped>
.custom-select {
  border: 2px solid #b99c7a;
  border-radius: 0;
}

.tab-bar-card {
  border-radius: 5px;
  border: #ccaf8b 4px solid;
  background-color: #e1c8aa;
  text-align: center;
  position: relative;
}

.list-panel {
  margin-top: 20px;
  background-color: #b99c7a;
  padding: 5px 0;
}

.list-card {
  padding: 0 5px;
  margin: 10px;
  background-color: #fff2e2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #785b38;
}

.selected-list-card {
  padding: 0 5px;
  color: #785b38;
  margin: 10px;
  background-color: #ffc872;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.score {
  background-color: #e0c8aa;
  width: 60px;
  text-align: right;
}
.selected-score {
  background-color: #fff2e2;
  width: 60px;
  text-align: right;
}

.text-brd-md {
  text-shadow: #8b5629 2px 0px 0px, #8b5629 1.75517px 0.958851px 0px,
    #8b5629 1.0806px 1.68294px 0px, #8b5629 0.141474px 1.99499px 0px,
    #8b5629 -0.832294px 1.81859px 0px, #8b5629 -1.60229px 1.19694px 0px,
    #8b5629 -1.97998px 0.28224px 0px, #8b5629 -1.87291px -0.701566px 0px,
    #8b5629 -1.30729px -1.5136px 0px, #8b5629 -0.421592px -1.95506px 0px,
    #8b5629 0.567324px -1.91785px 0px, #8b5629 1.41734px -1.41108px 0px,
    #8b5629 1.92034px -0.558831px 0px;
}

.text-brd-sm {
  text-shadow: #8b5629 1px 0px 0px, #8b5629 0.540302px 0.841471px 0px,
    #8b5629 -0.416147px 0.909297px 0px, #8b5629 -0.989992px 0.14112px 0px,
    #8b5629 -0.653644px -0.756802px 0px, #8b5629 0.283662px -0.958924px 0px,
    #8b5629 0.96017px -0.279415px 0px;
}
.selected-name {
  font-size: 20px;
  color: #fff;
  text-shadow: #8b5629 2px 0px 0px, #8b5629 1.75517px 0.958851px 0px,
    #8b5629 1.0806px 1.68294px 0px, #8b5629 0.141474px 1.99499px 0px,
    #8b5629 -0.832294px 1.81859px 0px, #8b5629 -1.60229px 1.19694px 0px,
    #8b5629 -1.97998px 0.28224px 0px, #8b5629 -1.87291px -0.701566px 0px,
    #8b5629 -1.30729px -1.5136px 0px, #8b5629 -0.421592px -1.95506px 0px,
    #8b5629 0.567324px -1.91785px 0px, #8b5629 1.41734px -1.41108px 0px,
    #8b5629 1.92034px -0.558831px 0px;
}

.reward-panel {
  position: absolute;
  bottom: 5px;
  right: 5px;
  font-size: small;
}

.coin-box {
  line-height: 12px;
  background-color: #fff;
  border: #292218 solid 2px;
  margin: 0 3px;
  font-size: x-small;
}

.coin-img {
  background-color: #6b583c;
}
</style>